export default {
  methods: {

    getCompany (rootDirectory = 'branding') {
      return `${rootDirectory}/${process.env.VUE_APP_COMPANY_LOGO}`
    },

    getProfile (rootDirectory = 'branding') {
      return `${rootDirectory}/${process.env.VUE_APP_PROFILE_LOGO}`
    }

  }
}
